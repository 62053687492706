import React, { useState } from "react";
import { HiMenu } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { Element, Link } from "react-scroll";
import { SideBar } from "./SideBar";
import Logo from '../assets/aloha-logo.png';
import LogoAlt from '../assets/aloha-logo-alt.png';
import LogoAlt2 from '../assets/aloha-logo-alt2.png';

export const NavBar = () => {
  const [open, setOpen] = useState<Boolean>(false);
  const [logoHover, setLogoHover] = useState<Boolean>(false);

  const toggleLogo = () => {
    setLogoHover(!logoHover);
  }

  return (
    <Element
      name="navbar"
      className="fixed top-0 w-screen flex justify-between items-center bg-black text-white py-4 drop-shadow z-50"
    >
      <Link to="home" smooth={true} offset={-50}>
        <div data-aos="fade-in" className="pl-10 w-full cursor-pointer font-bold text-xl xl:pl-28" onMouseEnter={toggleLogo} onMouseLeave={toggleLogo}>
          {/* Aloha */}
          {logoHover && <img className="h-10 w-40" src={LogoAlt2} alt="Aloha logo" />}
          {!logoHover && <img className="h-10 w-40" src={Logo} alt="Aloha logo" />}
        </div>
      </Link>
      <div className="hidden xl:flex pr-28 w-full justify-end align-middle font-semibold text-lg">
        <Link to="home" smooth={true} offset={-50}>
          <div className="cursor-pointer pr-6 hover:text-sky-500 transition duration-300 ease-out">
            Home
          </div>
        </Link>
        <Link to="services" smooth={true} offset={-50}>
          <div className="cursor-pointer pr-6 hover:text-sky-500 transition duration-300 ease-out">
            Services
          </div>
        </Link>
        <Link to="about" smooth={true} offset={-150}>
          <div className="cursor-pointer pr-6 hover:text-sky-500 transition duration-300 ease-out">
            About
          </div>
        </Link>
        <Link to="technologies" smooth={true} offset={-50}>
          <div className="cursor-pointer pr-6 hover:text-sky-500 transition duration-300 ease-out">
            Technologies
          </div>
        </Link>
        <Link to="contact" smooth={true} offset={-50}>
          <div className="cursor-pointer hover:text-sky-500 transition duration-300 ease-out">
            Contact Us
          </div>
        </Link>
      </div>
      <div className="pr-10 xl:pr-28 flex items-center xl:hidden cursor-pointer hover:text-sky-500 transition duration-300 ease-out">
        {!open && <HiMenu size="1.2rem" data-aos="fade-in" data-aos-duration="400" onClick={() => setOpen(!open)} />}
        {open && <IoMdClose size="1.2rem" data-aos="fade-left" data-aos-duration="400" onClick={() => setOpen(!open)} />}
      </div>
      {open && <SideBar handleClick={() => setOpen(false)} />}
    </Element>
  );
};
