import React from "react";
import { Element } from "react-scroll";
import BgLaptop from "../assets/bg-laptop.jpg";

export const Home = () => {
  return (
    <Element
      name="home"
      className="bg-black w-full flex justify-center align-middle h-full z-10"
    >
      <div
        data-aos="fade-up"
        className="text-5xl text-white font-extrabold absolute my-56 ml-6 xl:my-96"
      >
        One-stop solution for <span className="text-sky-500">web</span> and{" "}
        <span className="text-sky-500">mobile</span> applications.
        <div className="text-2xl mt-4 ml-1 font-thin">Fast. Reliable. Committed.</div>
      </div>
      <img
        src={BgLaptop}
        alt="Background"
        className="blur-sm opacity-25 h-screen w-full object-none"
      />
    </Element>
  );
};
