import React from 'react';
import { FaConnectdevelop } from 'react-icons/fa';
import { GiHumanTarget } from 'react-icons/gi';
import { VscWorkspaceTrusted } from 'react-icons/vsc';
import AboutIcon from '../assets/aboutUsIcon.svg';
import BgAbout from '../assets/bg-about-blue.svg';
import { AiOutlineTeam } from "react-icons/ai";
import { Element } from "react-scroll";

const divStyle = {
  backgroundRepeat: 'no-repeat',
  padding: '80px 0px 120px',
  color: 'blue',
  marginTop: '130px',
  backgroundImage: 'url(' + BgAbout + ')',
};

export const AboutUs = () => {
  const cardParentClass =
    "flex col-span-10 md:col-span-6 lg:col-span-3 w-full align-middle justify-center flex-col gap-14 mb-14";
  return (
    <Element
      name="about"
      data-aos="fade-in"
      className="grid grid-cols-10 bg-[length:500%] sm:bg-[length:250%] lg:bg-cover"
      style={divStyle}
    >
      <div
        data-aos="fade-right"
        className="col-span-10 md:col-span-4 flex row-span-2 lg:row-span-1 lg:col-span-4"
      >
        <img className="w-full p-12" src={AboutIcon} alt="Background" />
      </div>
      <div data-aos="fade-left" className={cardParentClass}>
        {aboutUs
          .filter((au: AboutUsInfo) => au.position == "LEFT")
          .map((au: AboutUsInfo, index: number) => (
            <AboutUsCard
              key={index}
              body={au.body}
              title={au.title}
              icon={au.icon}
            />
          ))}
      </div>
      <div data-aos="fade-left" className={cardParentClass}>
        {aboutUs
          .filter((au: AboutUsInfo) => au.position == "RIGHT")
          .map((au: AboutUsInfo, index: number) => (
            <AboutUsCard
              key={index}
              body={au.body}
              title={au.title}
              icon={au.icon}
            />
          ))}
      </div>
    </Element>
  );
};

const AboutUsCard = (au: AboutUsInfo) => {
  return (
    <div className="flex font-sans items-center space-x-6 md:mr-10 bg-sky-500 md:bg-transparent md:p-0 p-8 rounded-2xl mx-4 md:mx-0">
      <div className="flex-shrink-0 bg-white rounded-full p-3">{au.icon}</div>
      <div className="flex-1 min-w-0">
        <p className="text-xl font-bold text-white mb-3">{au.title}</p>
        <p className="text-base text-white">{au.body}</p>
      </div>
    </div>
  );
};

export interface AboutUsInfo {
  icon: any;
  title: string;
  body: string;
  position?: "LEFT" | "RIGHT";
}

const iconClass = "w-10 h-10 md:w-12 md:h-12 text-orange-500";

const aboutUs: AboutUsInfo[] = [
  {
    title: "User oriented approach",
    body: "We are focused on tailoring the custom software solution for your specific needs.",
    icon: <FaConnectdevelop className={iconClass} />,
    position: "LEFT",
  },
  {
    title: "Super agility",
    body: "Flexible schedule, unlimited remote work.",
    icon: <GiHumanTarget className={iconClass} />,
    position: "LEFT",
  },
  {
    title: "Trust from day one",
    body: "We got your back.",
    icon: <VscWorkspaceTrusted className={iconClass} />,
    position: "RIGHT",
  },
  {
    title: "Team work",
    body: "Talent wins games, but teamwork and intelligence win championships.",
    icon: <AiOutlineTeam className={iconClass} />,
    position: "RIGHT",
  },
];
