import React, { useState } from "react";
import { FaBug, FaCloud, FaLaptopCode, FaMobileAlt } from "react-icons/fa";
import { GiFamilyTree } from "react-icons/gi";
import { MdPeople } from "react-icons/md";
import { Element } from "react-scroll";
import { ServiceCard } from "./ServiceCard";

export const Service = () => {
  const [serviceCardInfo, setServiceCardInfo] = useState<ServiceCardInfo[]>([
    {
      icon: <FaLaptopCode />,
      title: "Software Development",
      body: "Researching, designing, implementing and managing software products.",
    },
    {
      icon: <GiFamilyTree />,
      title: "Systems Architecture",
      body: "Providing top industry trends for the systems architecture.",
    },
    {
      icon: <FaMobileAlt />,
      title: "Mobile",
      body: "Crafting and maintaining high quality mobile applications.",
    },
    {
      icon: <FaBug />,
      title: "Quality Assurance",
      body: "Ensuring that all quality requirements and expectancies are in place.",
    },
    {
      icon: <FaCloud />,
      title: "Cloud Computing",
      body: "Delivering cloud-oriented computing services.",
    },
    {
      icon: <MdPeople />,
      title: "Strategy Consulting",
      body: "Performing quantitative and qualitative analyses to drive the proposed solution.",
    },
  ]);

  // bg-gradient-to-b from-sky-50 to-white
  return (
    <Element name="services" className="xl:h-screen w-full z-10">
      <div className="md:container mx-auto mt-10 p-8 md:p-16">
        <div className="grid grid-cols-7 gap-10 w-full h-full">
          <div className="col-span-7 h-full flex flex-col justify-center xl:col-span-2">
            <div data-aos="fade-right" className={`text-3xl xl:text-right`}>
              We provide custom web and mobile solutions and digital services
              that help <span className="text-sky-500">start-ups</span> and{" "}
              <span className="text-sky-500">businesses</span> thrive.
            </div>
          </div>
          <div className="col-span-7 xl:col-span-5">
            <div
              className={`grid grid-cols-1 gap-8 xl:gap-14 w-full h-full md:grid-cols-2 xl:grid-cols-3`}
            >
              {serviceCardInfo &&
                serviceCardInfo.length > 0 &&
                serviceCardInfo.map((sci: ServiceCardInfo, idx: number) => {
                  return (
                    <ServiceCard
                      key={idx}
                      icon={sci.icon}
                      title={sci.title}
                      body={sci.body}
                    ></ServiceCard>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export interface ServiceCardInfo {
  icon: any;
  title: string;
  body: string;
}
