import React from "react";
import { ServiceCardInfo } from "./Service";

export const ServiceCard = (serviceCardInfo: ServiceCardInfo) => {
  return (
    <>
      <div
        data-aos="fade-left"
        className="drop-shadow-xl bg-white text-black flex flex-row items-center justify-start z-10 rounded-2xl xl:hidden"
      >
        <div className="text-sky-500 text-4xl p-6">{serviceCardInfo.icon}</div>
        <div className="flex flex-col py-4 pr-6">
          <div className="title font-medium text-xl">
            {serviceCardInfo.title}
          </div>
          <div className="body pt-5">{serviceCardInfo.body}</div>
        </div>
      </div>

      <div
        data-aos="fade-left"
        className="drop-shadow-xl p-10 bg-white text-black flex-col z-10 rounded-2xl hidden xl:flex"
      >
        <div className="text-sky-500 text-4xl">{serviceCardInfo.icon}</div>
        <div className="title pt-10 font-medium text-xl">
          {serviceCardInfo.title}
        </div>
        <div className="body pt-5">{serviceCardInfo.body}</div>
      </div>
    </>
  );
};
