import React, { useEffect } from "react";
import "./App.scss";
import { AboutUs } from "./components/AboutUs";
import { Home } from "./components/Home";
import { Service } from "./components/Service";
import { Technologies } from "./components/Technologies";
import { NavBar } from "./layout/NavBar";
import "aos/dist/aos.css";
import Aos from "aos";
import { Contact } from "./components/Contact";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <div className="content overflow-hidden">
      <NavBar />
      <Home />
      <Service />
      <AboutUs />
      <Technologies />
      <Contact />
    </div>
  );
}

export default App;
