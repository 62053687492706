import React from 'react'
import { SiReact, SiIos, SiJenkins, SiKotlin, SiMongodb, SiMysql, SiPhp, SiPostgresql, SiAngular, SiJava, SiPython, SiAndroid, SiAmazonaws, SiNodedotjs, SiLaravel } from 'react-icons/si';
import { Element } from "react-scroll";

export const Technologies = () => {
  return (
    <Element
      name="technologies"
      className="technologies-content overflow-x-hidden my-10"
    >
    <div className="technologies-content overflow-x-hidden">
      <div className="technologies">
      {/* md:rotate-[-5deg] */}
        <div className="grid text-sky-500 grid-cols-9 md:grid-cols-10 gap-5">
          <div data-aos="fade-in" data-aos-offset="100" data-aos-duration="400" className='col-span-10 text-2xl md:text-4xl font-thin text-black text-center'>
            Technologies
          </div>
          {
            technologies
              .map((t: Technology, index: number) => <Technology key={index} title={t.title} index={index} icon={t.icon} /> )
          }
        </div>
      </div>
    </div>
    </Element>
  )
}

const Technology = (t: Technology) => {
  return (
    <div data-aos="fade-up" data-aos-offset={(t.index ?? 1) * 15} data-aos-duration="900" className="col-span-3 md:col-span-2 m-auto" title={t.title}>
      <div title={t.title} className="flex-shrink-0 rounded-full text-4xl md:text-5xl p-1 md:p-3">
        {t.icon}
      </div>
    </div>
  )
}
export interface Technology {
  title: string;
  index?: number;
  // iconPath: string;
  icon: any;
}


const technologies: Technology[] = [
  {
    title: 'React native',
    icon: <SiReact  data-aos="fade-in"  title="React native"/>
  },
  {
    title: 'Java',
    icon: <SiJava  data-aos="fade-in"  title="Java"/>
  },
  {
    title: 'Angular',
    icon: <SiAngular  data-aos="fade-in"  title="Angular"/>
  },
  {
    title: 'Android',
    icon: <SiAndroid  data-aos="fade-in"  title="Android"/>
  },
  {
    title: 'IOS',
    icon: <SiIos  data-aos="fade-in"  title="IOS"/>
  },
  {
    title: 'Jenkins',
    icon: <SiJenkins  data-aos="fade-in"  title="Jenkins"/>
  },
  {
    title: 'Kotlin',
    icon: <SiKotlin  data-aos="fade-in"  title="Kotlin"/>
  },
  {
    title: 'MongoDB',
    icon: <SiMongodb  data-aos="fade-in"  title="MongoDB"/>
  },
  {
    title: 'MySql',
    icon: <SiMysql  data-aos="fade-in"  title="MySql"/>
  },
  {
    title: 'Postgres',
    icon: <SiPostgresql  data-aos="fade-in"  title="Postgres"/>
  },
  {
    title: 'PHP',
    icon: <SiPhp  data-aos="fade-in"  title="PHP"/>
  },
  {
    title: 'AWS',
    icon: <SiAmazonaws  data-aos="fade-in"  title="AWS"/>
  },
  {
    title: 'NodeJS',
    icon: <SiNodedotjs  data-aos="fade-in"  title="NodeJS"/>
  },
  {
    title: 'Python',
    icon: <SiPython  data-aos="fade-in"  title="Python"/>
  },
  {
    title: 'Laravel',
    icon: <SiLaravel  data-aos="fade-in"  title="Laravel"/>
  },
]
