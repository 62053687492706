import { Link } from "react-scroll";

export const SideBar = ({ handleClick }: any) => {
  return (
    <div
      data-aos="fade-left"
      data-aos-mirror="true"
      data-aos-easing="ease-in-out"
      data-aos-duration="400"
      className="top-14 right-0 w-screen bg-gray-900  p-10 pl-20 text-white fixed h-screen "
    >
      <h2 className="mt-20 text-4xl font-semibold text-white">
        <Link
          to="home"
          smooth={true}
          offset={-50}
          onClick={() => handleClick()}
        >
          <div data-aos="flip-up" className="cursor-pointer hover:text-sky-500 transition duration-300 ease-out">
            Home
          </div>
        </Link>
        <Link
          to="services"
          smooth={true}
          offset={-50}
          onClick={() => handleClick()}
        >
          <div data-aos="flip-up" className="cursor-pointer hover:text-sky-500 transition duration-300 ease-out">
            Services
          </div>
        </Link>
        <Link
          to="about"
          smooth={true}
          offset={-150}
          onClick={() => handleClick()}
        >
          <div data-aos="flip-up" className="cursor-pointer hover:text-sky-500 transition duration-300 ease-out">
            About
          </div>
        </Link>
        <Link
          to="technologies"
          smooth={true}
          offset={-50}
          onClick={() => handleClick()}
        >
          <div data-aos="flip-up" className="cursor-pointer hover:text-sky-500 transition duration-300 ease-out">
            Technologies
          </div>
        </Link>
        <Link
          to="contact"
          smooth={true}
          offset={-50}
          onClick={() => handleClick()}
        >
          <div data-aos="flip-up" className="cursor-pointer hover:text-sky-500 transition duration-300 ease-out">
            Contact Us
          </div>
        </Link>
      </h2>
    </div>
  );
};
