import React, { useState } from "react";
import { Element } from "react-scroll";
import emailjs from '@emailjs/browser';
import { ImSpinner8 } from "react-icons/im";

export const Contact = () => {
  let initialForm = { from_name: '', message: '', reply_to: '' };
  const [contactForm, setContactform] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState('Send');

  const handleChange = (target: any) => {
    setContactform({
      ...contactForm,
      [target.target.name]: target.target.value
    });
  }

  const resetForm = () => {
    setContactform(initialForm);
  }

  const send = () => {
    if (!contactForm.from_name || !contactForm.message || !contactForm.reply_to || btnText != "Send") return;
    setLoading(true);
    emailjs.send('service_5p3qqwf', 'template_m1o6278', contactForm, 'fDl4P_X3zaQe4rdW2')
      .then((response) => {
        resetForm();
        setBtnText('Email sent, thanks');
        setTimeout(() => setBtnText('Send'), 2500);
      }, (err) => {
        setBtnText('Oops, Try again later...')
        setTimeout(() => resetForm(), 1500);
      },).finally(() => setLoading(false));
  }
  return (
    <Element name="contact" className="h-full w-screen">
      <div className="flex flex-col xl:flex-row items-center justify-around p-4 xl:p-10">
        <div className="w-full p-8 xl:px-20">
          <p data-aos="fade-down" className="text-5xl xl:text-7xl text-sky-500">We're here!</p>
          <p data-aos="fade-up" className="text-xl text-gray-700 my-4">
            If you have any questions, feel free to contact us. We will respond
            as soon as possible.
          </p>
        </div>
        <div data-aos="fade-left" className="w-full drop-shadow-xl p-8 xl:px-20">
          <form className="bg-sky-500 shadow-md rounded-2xl px-8 pt-6 pb-8 mb-4">
            <div className="mb-2">
              <label className="block text-white text-sm font-bold mb-2">
                Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                name="from_name"
                value={contactForm.from_name}
                onChange={handleChange}
                type="text"
                placeholder="Name"
              />
            </div>
            <div className="mb-2">
              <label className="block text-white text-sm font-bold mb-2">
                Email
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                name="reply_to"
                value={contactForm.reply_to}
                onChange={handleChange}
                placeholder="user@example.com"
              />
            </div>
            <div className="mb-2">
              <label className="block text-white text-sm font-bold mb-2">
                Message
              </label>
              <textarea
                className="shadow appearance-none border rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline"
                id="message"
                name="message"
                value={contactForm.message}
                onChange={handleChange}
                placeholder="Message"
                rows={5}
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                onClick={send}
                className="bg-white hover:bg-gray-100 text-sky-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
              >
                {
                  loading &&
                  <ImSpinner8 className="animate-spin inline" />
                }
                <span className="mx-2 mt-1">
                  {btnText}
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </Element>
  );
};
